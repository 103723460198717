import { debounce } from 'lodash-es';
import { mapGetters, mapState, mapActions } from 'vuex';

import GenderControl from '@/components/auth/controls/gender-control/index.vue';
import BirthdateControl from '@/components/auth/controls/birthdate-control/index.vue';

// @vue/component
export default {
  name: 'AdditionalProfileInfoPopup',

  components: {
    GenderControl,
    BirthdateControl,
  },

  data() {
    return {
      gender: null,
      birthdate: null,

      isBusy: false,

      minAge: 18,
    };
  },

  computed: {
    isBirthdateEmpty() {
      return !this.currentUser.birthdate;
    },

    isValid() {
      return !!this.birthdate;
    },

    ...mapState(['currentUser', 'authenticated']),
    ...mapGetters(['isUserCoach']),
  },

  watch: {
    authenticated: {
      handler: function (val) {
        if (val) {
          this.showPopup();
        }
      },
    },
  },

  mounted() {
    if (this.authenticated) {
      this.showPopup();
    }

    this.$root.$on('show-additional-profile-info-popup', () => {
      this.showPopup();
    });
  },

  methods: {
    showPopup: debounce(function () {
      if (
        this.$route.name != 'onboarding-success-page' &&
        this.isBirthdateEmpty &&
        this.isUserCoach
      ) {
        this.gender = this.currentUser.gender;
        this.birthdate = this.currentUser.birthdate;

        this.$refs.popup.show();
      }
    }, 2000),

    /**
     * Save data
     */
    save() {
      this.isBusy = true;

      const data = {
        birthdate: this.birthdate,
      };

      if (this.gender) {
        data.gender = this.gender;
      }

      this.updateProfile(data)
        .then(() => {
          this.$refs.popup.hide();
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    ...mapActions(['updateProfile']),
  },
};
