export default {
  fitr: 'FITR',
  avatar: 'Avatar',
  schedule: 'schedule',
  s1d1: 's1d1',
  custom: 'custom',
  fixed: 'fixed',
  payment_alt: 'Powered by Stripe',
  athlete_perfomance: 'Athlete’s Performances',
  gowod: 'gowod',

  add_tip: 'Add tip',
  add_session: 'Add session',
  add_challenge: 'Add challenge',
  add_template: 'Add template',
  welcome_nav: 'Welcome nav',
  score: 'Score',
  help: 'Help',
  video: 'Video',
  challenge: 'Challenge',
  plus: 'Plus',
  minus: 'Minus',
  clear: 'Clear',
  danger: 'Danger',
  clone: 'Clone',
  okay: 'Okay',
  create: 'Create',
  update: 'Update',
  cut: 'Cut',
  copy: 'Copy',
  paste: 'Paste',
  delete: 'Delete',
  close: 'Close',
  document: 'Document',
  icon: 'Icon',
  edit: 'Edit',
  publish: 'Publish',
  warning: 'Warning',

  up: 'Up',
  down: 'Down',

  app_store: 'App Store',
  play_store: 'Play Store',
};
