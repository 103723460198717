const Layout = () => import('./Layout.vue');
const TemplatesLayout = () => import('./weeks/index.vue');
const TemplatesList = () => import('./weeks/list/index.vue');
const TemplateForm = () =>
  import('@/components/profile/coach/programs/schedule/index.vue');

const DaysLayout = () => import('./days/index.vue');
const DaysList = () => import('./days/list/index.vue');

export default [
  {
    path: 'templates',
    component: Layout,

    children: [
      {
        path: '',
        name: 'workout_library',
        redirect: { name: 'workout_library_days' },
      },
      {
        path: 'presets',
        component: TemplatesLayout,

        children: [
          {
            path: '',
            name: 'workout_library_templates',
            component: TemplatesList,
          },
          {
            path: 'create',
            name: 'create_workout_library_template',
            component: TemplateForm,
            meta: { hideFooter: true },
          },
          {
            path: ':plan_id',
            name: 'edit_workout_library_template',
            component: TemplateForm,
            props: true,
            meta: { hideFooter: true, type: 'template' },
          },
        ],
      },
      {
        path: 'days',
        component: DaysLayout,

        children: [
          {
            path: '',
            name: 'workout_library_days',
            component: DaysList,
          },
        ],
      },
    ],
  },
];
