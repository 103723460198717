import header from './header';
import footer from './footer';
import cookies_policy from './cookies';
import home from './home';
import media from './media';
import modal from './modal';
import plan_billing from './plan_billing';

import pages from './pages';

import sign_up from './auth/sign_up';
import confirmation from './auth/confirmation';
import forgot_password from './auth/forgot-password';
import reset_password from './auth/reset-password';
import sign_in from './auth/sign-in';
import auth_success from './auth/success';

import feed from './feed';
import profile from './profile';
import membership from './membership';
import leaderboards from './leaderboards/index';

import fields from './common/fields';
import errors from './common/errors';
import buttons from './common/buttons';
import shared from './shared';
import common from './common.json';
import alts from './alts';

export default {
  ...common,

  header,
  footer,
  home,
  cookies_policy,
  media,
  modal,
  plan_billing,
  pages,
  shared,

  auth: {
    sign_in,
    sign_up,
    confirmation,
    forgot_password,
    reset_password,
    success: auth_success,
    ...common.auth,
  },

  feed,
  profile,
  membership,
  leaderboards,
  fields,
  errors,
  buttons,
  alts,
};
