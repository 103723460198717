import Vue from 'vue';
import Vuex from 'vuex';
import { i18n } from '@/i18n';

import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

import FeedStore from '@/components/feed/store';
import SettingsStore from './modules/settings';

Vue.use(Vuex);

export default new Vuex.Store({
  state: function () {
    return {
      authenticated: false,
      authError: null,
      alerts: {
        info: null,
        type: null,
      },
      currentUser: {},
      languages: {},
    };
  },

  modules: {
    feed: FeedStore,
    settings: SettingsStore,
  },

  mutations: {
    AuthUser(state) {
      state.authenticated = true;
      state.authError = null;
    },
    SingOutUser(state) {
      state.authenticated = false;
      state.currentUser = {};
    },
    AuthError(state, e) {
      state.authError = e;
    },
    Alerts(state, e) {
      state.alerts = e;
    },

    CurrentUser(state, data) {
      state.currentUser = data;
    },

    PrivateUser() {
      console.log('❌ ~ file: store.js ~ line 62 ~ PrivateUser');
    },

    CurrentUserName(state, e) {
      state.currentUser.full_name = e;
    },

    CurrentPrivateUserName(state, e) {
      state.currentUser.private_title = e;
    },

    CurrentUserAvatar(state, e) {
      state.currentUser.avatar_url = e;
    },

    changeHasNewNotifications(state, status) {
      state.currentUser.has_new_notifications = status;
    },

    changeDefaultWeightUnit(state, unit) {
      state.currentUser.weight = unit;
    },

    changeDefaultLengthUnit(state, unit) {
      state.currentUser.length = unit;
    },

    setViewedPopups(state, popups) {
      state.currentUser.popup_storage = popups;
    },

    setCurrentUserCountry(state, country) {
      state.currentUser.country = country;
    },

    setCurrentUserCurrency(state, currency) {
      state.currentUser.currency = currency;
    },

    setStripeAccount: (state) => {
      state.currentUser.has_stripe_account = true;
    },

    setCurrentUserLanguage: (state, lang) => {
      state.currentUser.client_language = lang;
    },

    setLanguages: (state, languages) => {
      state.languages = languages;
    },
  },

  actions: {
    getCurrentUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('users/me')
          .then((response) => {
            commit('CurrentUser', response.data);
            commit('AuthUser');

            resolve();
          })
          .catch((error) => {
            commit('AuthError', error.data);

            reject(error);
          });
      });
    },

    signin({ commit }, { email, password }) {
      const params = {
        email,
        password,
        client_id: import.meta.env.VITE_CLIENT_ID,
        client_secret: import.meta.env.VITE_CLIENT_SECRET,
      };

      return new Promise((resolve, reject) => {
        HTTP.post('users/sign_in', params)
          .then(({ data }) => {
            saveToken(data.access_token, data.token_type, commit);

            commit('CurrentUser', data.user);

            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    signup({ commit }, data) {
      // { email, password, first_name, last_name, role, birthdate, agree, country, currency }
      const params = {
        ...data,
        client_id: import.meta.env.VITE_CLIENT_ID,
        client_secret: import.meta.env.VITE_CLIENT_SECRET,
      };

      return new Promise((resolve, reject) => {
        HTTP.post('users/sign_up', params)
          .then(({ data }) => {
            if (data?.user?.role == 'coach') {
              // Set Current User
              commit('CurrentUser', data.user);

              // Set Token
              saveToken(data.access_token, data.token_type, commit);

              localStorage.removeItem('role');
            } else if (
              data?.user?.role !== 'coach' &&
              params.user?.registration == 'rogue'
            ) {
              saveToken(data.access_token, data.token_type, commit);
              commit('CurrentUser', data.user);
            }

            resolve();
          })
          .catch((e) => {
            HttpErrorsHandling(e);
            reject();
          });
      });
    },

    signout() {
      return new Promise((resolve, reject) => {
        HTTP.delete('users/sign_out')
          .then(() => {
            destroyToken();

            window.localStorage.removeItem('fitr_return_path');

            window.localStorage.removeItem('utm_query');

            window.location.replace('/');

            resolve();
          })
          .catch((e) => {
            HttpErrorsHandling(e);

            reject();
          });
      });
    },

    deleteUser() {
      return new Promise((resolve, reject) => {
        HTTP.delete('users')
          .then(() => {
            destroyToken();

            window.localStorage.removeItem('fitr_return_path');

            window.localStorage.removeItem('utm_query');

            window.location.replace('/');

            resolve();
          })
          .catch((e) => {
            HttpErrorsHandling(e);

            reject();
          });
      });
    },

    confirmationUser({ commit }, { confirmation_token }) {
      const params = {
        confirmation_token,
        client_id: import.meta.env.VITE_CLIENT_ID,
        client_secret: import.meta.env.VITE_CLIENT_SECRET,
      };

      return new Promise((resolve, reject) => {
        HTTP.post('users/confirm', params)
          .then((response) => {
            saveToken(
              response.data.access_token,
              response.data.token_type,
              commit
            );
            commit('CurrentUser', response.data.user);
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    resetPassword(_, { email }) {
      const params = {
        email,
      };

      return new Promise((resolve, reject) => {
        HTTP.post('users/password', params)
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            HttpErrorsHandling(e);

            reject();
          });
      });
    },

    createNewPassword(
      { commit },
      { password, password_confirmation, reset_password_token }
    ) {
      const params = {
        password,
        password_confirmation,
        reset_password_token,
        client_id: import.meta.env.VITE_CLIENT_ID,
        client_secret: import.meta.env.VITE_CLIENT_SECRET,
      };

      return new Promise((resolve, reject) => {
        HTTP.put('users/password', params)
          .then((response) => {
            // Set Token
            saveToken(
              response.data.access_token,
              response.data.token_type,
              commit
            );

            // Set Current User
            commit('CurrentUser', response.data.user);

            resolve(response);
          })
          .catch((e) => {
            HttpErrorsHandling(e);

            reject();
          });
      });
    },

    editPassword(
      { commit },
      { password, password_confirmation, current_password }
    ) {
      const params = {
        user: {
          password,
          password_confirmation,
          current_password,
        },
      };

      return new Promise((resolve, reject) => {
        HTTP.patch('users/password', params)
          .then(() => {
            commit('Alerts', {
              info: { message: i18n.t('auth.reset_password.alert') },
              type: 'congrats',
            });

            // Set Token
            // saveToken(response.data.access_token, response.data.token_type, commit);

            // Set Current User
            // commit('CurrentUser', response.data.user);

            resolve();
          })
          .catch(({ response }) => {
            commit('Alerts', { info: response.data, type: 'warn' });

            reject();
          });
      });
    },

    editAvatar({ commit }) {
      let data = this.state.currentUser;
      let url = 'users/avatar';
      let key = 'user[avatar]';

      // update avatar invited coach
      // if (payload.user) {
      //   data = payload.user;
      //   url = `invites/${payload.user.id}/avatar`;
      //   key = 'employee[avatar]';
      // }

      const params = new FormData();

      if (data.avatar_remove) {
        params.append(key, '');
      } else if (data.avatar) {
        params.append(key, data.avatar);
      }

      return new Promise((resolve, reject) => {
        HTTP.post(url, params, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
          .then(({ data }) => {
            commit('CurrentUserAvatar', data.avatar_url);

            resolve();
          })
          .catch((e) => {
            HttpErrorsHandling(e);

            reject();
          });
      });
    },

    updateProfile({ commit, getters }, payload) {
      const data = this.state.currentUser;

      const params = {};

      params.first_name = data.first_name || '';
      params.last_name = data.last_name || '';

      if (!getters.isUserCoach) {
        params.country = data.country;
        params.currency = data.currency;
        params.gym_type = data.gym_type;
        params.gym_id = data.gym;
        params.client_language = data.client_language;
      }

      if (payload?.birthdate) {
        params.birthdate = payload.birthdate;
      } else {
        params.birthdate = data.birthdate || '';
      }

      if (payload?.gender) {
        params.gender = payload.gender;
      } else {
        params.gender = data.gender || '';
      }

      return new Promise((resolve, reject) => {
        HTTP.post('users/me', params)
          .then((response) => {
            commit('CurrentUser', response.data);

            let data = {
              message: i18n.t(
                'profile.coach.programs.view.settings.saved_changes'
              ),
            };

            if (payload?.birthdate) {
              data.message = 'You are ready to go!';
            }

            commit('Alerts', { info: data, type: 'congrats' });

            resolve();
          })
          .catch((e) => {
            HttpErrorsHandling(e);
            reject();
          });
      });
    },

    updateCurrentUserLanguage: ({ commit }, client_language) => {
      return new Promise((resolve, reject) => {
        HTTP.post('users/me', {
          client_language,
        })
          .then(({ data }) => {
            commit('setCurrentUserLanguage', data.client_language);

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    setDefaultWeightUnit({ commit }, { weight }) {
      // TODO: remove Private
      console.log(
        '🚀 ~ file: store.js ~ line 498 ~ setDefaultWeightUnit ~ setDefaultWeightUnit',
        weight
      );

      return new Promise((resolve, reject) => {
        // HTTP.post('users/me', {
        HTTP.patch('users/private', {
          private: { weight },
        })
          .then(({ data }) => {
            commit('changeDefaultWeightUnit', data.user.weight);

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    setDefaultLengthUnit({ commit }, { length }) {
      // TODO: remove Private
      console.log(
        '🚀 ~ file: store.js ~ line 521 ~ setDefaultLengthUnit ~ setDefaultLengthUnit',
        length
      );

      return new Promise((resolve, reject) => {
        HTTP.patch('users/private', {
          private: { length },
        })
          .then(({ data }) => {
            commit('changeDefaultLengthUnit', data.user.length);

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    makePopupViewed({ commit }, { name }) {
      return HTTP.patch('users/popup_storage', {
        popup_name: name,
      }).then(({ data }) => {
        commit('setViewedPopups', data.popup_storage);
      });
    },

    getLanguages({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('languages')
          .then((response) => {
            commit('setLanguages', response.data);

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },

  getters: {
    currentUserId: (state) => {
      return state.currentUser.id;
    },

    isUserCoach: (state) => {
      return state.currentUser.role == 'coach';
    },

    isAccountOwner: (state) => {
      return state.currentUser.is_account_owner;
    },

    currentUserTitle: (state) => {
      return state.currentUser.full_name;
    },

    isFinancialAviable: (_state, getters) => {
      return getters.isAccountOwner;
    },

    defaultWeightUnit: (state) => {
      return state.currentUser.weight;
    },

    defaultLengthUnit: (state) => {
      return state.currentUser.length;
    },

    isUsaBased: (state) => {
      return state.currentUser.country == 'US';
    },

    viewedPopups: (state) => {
      return state.currentUser.popup_storage;
    },

    hasStripeAccount: (state) => {
      return state.currentUser.has_stripe_account;
    },

    privateAccountId: (state) => {
      return state.currentUser.private_id;
    },

    privateAccountTitle: (state) => {
      return state.currentUser.private_title;
    },

    clientLanguage: (state) => {
      return state.currentUser.client_language;
    },

    supportedLanguages: (state) => {
      return state.languages;
    },
  },
});

/**
 * @param {string} access_token - Access Token
 * @param {string} token_type - Type of Token
 * @param {Function} cb - callback
 */
function saveToken(access_token, token_type, cb) {
  localStorage.setItem('access_token', access_token);
  localStorage.setItem('token_type', token_type);

  HTTP.defaults.headers.common[
    'Authorization'
  ] = `${token_type} ${access_token}`;

  // user is auth ^_^
  cb('AuthUser');
}

/**
 * Destroys token
 */
function destroyToken() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('token_type');
  localStorage.removeItem('back_to_plan');

  HTTP.defaults.headers.common['Authorization'] = '';
}
