<script>
import useRegisterSW from '@/mixins/useRegisterSW';

const intervalMS = 60 * 60 * 1000;

export default {
  name: 'ReloadPrompt',
  mixins: [useRegisterSW],

  methods: {
    /**
     * Add a service worker
     * @param {any} r Service worker
     */
    handleSWManualUpdates(r) {
      r &&
        setInterval(() => {
          r.update();
        }, intervalMS);
    },

    /**
     * Reload the page
     */
    reloadNow() {
      if (this.offlineReady) {
        window.location.reload();
      } else {
        this.updateServiceWorker();
      }
    },
  },
};
</script>

<template>
  <b-modal
    :visible="needRefresh"
    :title="$t('update_modal.title')"
    hide-header-close
    size="572"
    lazy
  >
    <p class="mb-0">{{ $t('update_modal.text') }}</p>
    <template #modal-footer="{ close }">
      <button class="btn btn-outline-dark" type="button" @click.prevent="close">
        {{ $t('update_modal.cancel') }}
      </button>
      <button class="btn btn-primary" type="button" @click.prevent="reloadNow">
        {{ $t('update_modal.ok') }}
      </button>
    </template>
  </b-modal>
</template>
