import Vue from 'vue';
import VueRouter from 'vue-router';
const { isNavigationFailure, NavigationFailureType } = VueRouter;

import NotFound from '@/components/pages/NotFound.vue';
import Main from '@/components/pages/Main.vue';
import AuthRoutes from '@/components/auth/routes.js';
import PagesRoutes from '@/components/pages/routes.js';

import ProfileRoutes from '@/components/profile/routes.js';

import FeedRoutes from '@/components/feed/routes.js';
import LeaderboardsRoutes from '@/components/leaderboards/routes.js';

import Store from '@/store/store.js';

Vue.use(VueRouter);

let mainRoutes = [
  {
    path: '',
    component: Main,
    name: 'main',

    beforeEnter: (to, from, next) => {
      if (Store.state.currentUser.role === 'user') {
        next({ name: 'user_profile_info', replace: true });
      } else {
        next({ name: 'my_plans', replace: true });
      }
    },
  },
  {
    path: '/fitr-style-guide',
    component: () => import('@/components/pages/styleGuide.vue'),
  },

  {
    path: '/404',
    name: '404',
    component: NotFound,
  },

  {
    path: '*',
    redirect: '/404',
  },
];

let routes = [
  ...mainRoutes,
  ...AuthRoutes,
  ...PagesRoutes,
  ...ProfileRoutes,
  ...FeedRoutes,
  ...LeaderboardsRoutes,
];

export const ROUTER = new VueRouter({
  mode: 'history',
  base: '/',
  routes: routes,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (!(to.meta.disableScrollBehavior && from.meta.disableScrollBehavior)) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    }
  },
});

ROUTER.afterEach(() => {
  document.activeElement && document.activeElement.blur();
});

ROUTER.beforeEach((to, from, next) => {
  let shouldRedirectBack = [
    'plan_purchase_form',
    'plan_purchase_shared_form',
    'user_billing_my_purchases',
  ].includes(to.name);

  if (
    to.name == 'my_plans_active_with_welcome_popup' &&
    !Store.state.authenticated
  ) {
    window.localStorage.setItem('utm_query', JSON.stringify(to.query));

    next({ name: 'onboarding-coaching-signup' });
  } else {
    if (['onboarding-coaching-signup', 'onboarding-signin'].includes(to.name)) {
      if (Store.state.authenticated) {
        next({ name: 'profile_main' });
      } else {
        if (
          shouldRedirectBack &&
          !window.localStorage.getItem('fitr_return_path')
        ) {
          window.localStorage.setItem('fitr_return_path', from.fullPath);
        }
        next();
      }
    } else {
      let isCoachProgramsPages = to.name?.indexOf('coach_program_');

      if (
        isCoachProgramsPages >= 0 &&
        (!Store.state.authenticated || !Store.getters.isUserCoach)
      ) {
        next({
          name: 'plan_purchase_form',
          params: { plan_id: to.params.plan_id },
        });
      } else if (to.meta.publicPage) {
        next();
      } else if (Store.state.authenticated) {
        next();
      } else {
        if (shouldRedirectBack) {
          window.localStorage.setItem('fitr_return_path', to.fullPath);
        }

        next({ name: 'onboarding-signin' });
      }
    }
  }
});

/**
 * @description - NavigationGuardRedirectErrorHandler
 * @param {Error} e - Catched error from router
 */
export const NavigationGuardRedirectErrorHandler = (e) => {
  if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
    Promise.reject(e);
  }
};
