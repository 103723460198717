export default {
  popup: {
    button: {
      create: 'Create a benchmark workout',
      update: 'Update benchmark workout',
      link: 'Link leaderboard',
    },

    multiple_intervals: {
      title: 'Multiple intervals',
      tooltip:
        'Split an overall score into intervals to see more detailed athlete performance data',
    },

    add_time_cap: {
      title: 'Time Cap',
      button: '+ Add a Time Cap',
      tooltip:
        'If a athlete misses the time cap we’ll ask them to add how much work they completed. We’ll automatically calculate a time penalty of 1 second per missed repetition.',
    },

    or: 'or',
  },
};
