export default {
  date_of_birth: 'Date of birth',
  date_of_birth_placeholder: 'MM/DD/YYYY',
  location: 'Location',
  location_tooltip:
    "We'll automatically display program prices based on the local currency where you're based.",
  location_placeholder: 'Please select your location',
  location_loading: 'Loading …',
  location_error: 'An error has occurred',
  location_try_again: 'Please try again later',
  currency: 'Currency',
  currency_texts: {
    loading: 'Loading…',
    select_a_currency: 'Please Select a Currency',
    error: 'An error has occurred',
    try_again: 'Please try again later',
  },

  gender: 'Gender',
  email: 'Email',
  password: 'Password',
  confirm_password: 'Confirm Password',
};
