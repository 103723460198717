<template>
  <div id="app" :class="bodyCssClass">
    <div class="app-wrap">
      <div v-if="isOffline" class="no-internet-connection system-noty danger">
        {{ $t('home.offline') }}
      </div>

      <div class="content">
        <router-view></router-view>
      </div>

      <app-footer v-if="!isFooterHidden"></app-footer>
    </div>

    <app-alerts></app-alerts>

    <additional-profile-info-popup></additional-profile-info-popup>

    <portal-target name="benchmarks-preview-popup"></portal-target>

    <ReloadPrompt></ReloadPrompt>

    <CookiesPolicy></CookiesPolicy>
  </div>
</template>

<script>
import { HTTP } from '@/utils/http-common';

import { mapState, mapGetters } from 'vuex';

import { connect, disconnect } from '@/utils/actioncable';

import { PortalTarget } from 'portal-vue';

import Alerts from '@/shared/Alerts.vue';

import AppFooter from '@/shared/app-footer/short/index.vue';
import AdditionalProfileInfoPopup from '@/shared/additional-profile-info-popup/index.vue';
import ReloadPrompt from '@/shared/reload-prompt/index.vue';
import CookiesPolicy from '@/shared/cookies-policy/index.vue';

export default {
  components: {
    AppAlerts: Alerts,
    AppFooter,
    AdditionalProfileInfoPopup,
    PortalTarget,
    ReloadPrompt,
    CookiesPolicy,
  },

  data: () => ({
    isOffline: false,

    alertsData: {
      title: null,
      text: null,
      type: null,
    },

    isFooterHidden: true,
  }),

  computed: {
    bodyCssClass() {
      return this.$route.meta.bodyCssClass;
    },

    ...mapState(['alerts', 'authenticated', 'currentUser']),
    ...mapGetters(['isUserCoach', 'clientLanguage']),
  },

  watch: {
    // TODO: have to be removed
    alerts() {
      const alert = this.alerts;
      let message = null;

      if (alert.type === null) {
        return;
      }

      switch (alert.type) {
        case 'warn':
          for (let msgObg in alert.info) {
            for (let msg in alert.info[msgObg]) {
              message = alert.info[msgObg][msg];

              this.$notify({
                group: 'alerts',
                type: alert.type,
                title: message || 'Warning!',
              });
            }
          }

          break;

        case 'info':
          this.$notify({
            group: 'alerts',
            type: alert.type,
            title: alert.info.message ? alert.info.message : 'Info message!',
          });

          break;

        case 'congrats':
          this.$notify({
            group: 'alerts',
            type: alert.type,
            title: alert.info.message ? alert.info.message : 'Congrats!',
          });

          break;

        case 'error':
          this.$notify({
            group: 'alerts',
            type: alert.type,
            title: alert.info.message ? alert.info.message : 'Whoops!',
          });

          break;

        default:
          this.$notify({
            group: 'alerts',
            type: alert.type,
            title: alert.info.message ? alert.info.message : 'Info message!',
          });
      }

      this.$store.commit('Alerts', { type: null });
    },

    authenticated: {
      handler: function (val) {
        if (val) {
          this.connectSockets();
        } else {
          this.disconnectSockets();
        }
      },
      immediate: true,
    },

    '$route.meta': {
      handler: function (to) {
        this.isFooterHidden = !!to.hideFooter;
      },
      immediate: true,
    },

    '$i18n.locale'(oldLocale, newLocale) {
      if (oldLocale != newLocale) {
        // Change Language header on language switch
        HTTP.defaults.headers.common['Language'] = this.$i18n.locale;
        this.$moment.locale(this.$i18n.locale);
      }
    },

    clientLanguage(lang) {
      if (lang) {
        this.$i18n.locale = lang;
        localStorage.setItem('client_language', lang);
      }
    },

    currentUser: {
      handler: function (user) {
        // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
        if (window.Intercom) {
          if (user?.id && user?.role != 'coach') {
            const userInfo = {
              name: user.full_name,
              user_id: user.id,
              email: user.email,
              created_at: user.created_at,
            };

            if (user.client_language) {
              userInfo.language_override = user.client_language;
            }

            window.Intercom('boot', {
              api_base: 'https://api-iam.intercom.io',
              app_id: import.meta.env.VITE_INTERCOM_KEY,

              ...userInfo,
            });
          } else {
            window.Intercom('shutdown');
          }
        }

        if (user?.id && !user?.client_language) {
          this.$i18n.locale = this.$i18n.fallbackLocale;
          localStorage.removeItem('client_language');
        }

        // Profitwell
        if (window.profitwell && user?.role == 'user' && user?.email) {
          window.profitwell('start', {
            user_email: user.email,
          });
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.$root.$on('show-new-user-info-popup', ({ role, invite }) => {
      this.showNewUserSuccessNotify({ role, invite });
    });

    if (window.localStorage.getItem('fitrShowNewUserInfoPopup')) {
      this.showNewUserSuccessNotify();

      window.localStorage.removeItem('fitrShowNewUserInfoPopup');
    }

    const clientLanguageStorage = localStorage.getItem('client_language');

    if (this.currentUser && this.currentUser.client_language) {
      this.$i18n.locale = this.currentUser.client_language;
    } else if (clientLanguageStorage && !this.isUserCoach) {
      this.$i18n.locale = clientLanguageStorage;
    }
  },

  created: function () {
    window.addEventListener('online', this.updateOnlineIndicator);
    window.addEventListener('offline', this.updateOnlineIndicator);

    window.addEventListener('resize', this.calc100vh);
    this.calc100vh();
  },

  beforeDestroy: function () {
    window.removeEventListener('online', this.updateOnlineIndicator);
    window.removeEventListener('offline', this.updateOnlineIndicator);

    window.removeEventListener('resize', this.calc100vh);
  },

  methods: {
    /**
     * Show notification
     * @param {{ role: string, invite: { token: string, title: string } }} root0 - notification data
     */
    showNewUserSuccessNotify({ role, invite } = {}) {
      let title = '';

      if (this.authenticated && this.isUserCoach) {
        if (role == 'invited') {
          if (invite.token && invite.title) {
            title = `Congrats, we’ve added you into '${invite.title}'.`;
          } else {
            title = `You're ready to go ${this.currentUser.full_name}!`;
          }
        } else {
          title = `You're ready to go ${this.currentUser.full_name}!`;
        }
      }

      if (title.length) {
        this.$notify({
          group: 'alerts',
          type: 'congrats',
          title: title,
        });
      }
    },

    /**
     * Calculate 100vh
     */
    calc100vh() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;

      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    /**
     * Update online indicator
     */
    updateOnlineIndicator() {
      this.isOffline = !window.navigator.onLine;
    },

    /**
     * Connect to sockets
     */
    connectSockets() {
      connect();
    },

    /**
     * Disconnect from sockets
     */
    disconnectSockets() {
      disconnect();
    },
  },
};
</script>

<style src="./app.scss" lang="scss"></style>
