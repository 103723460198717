const FONT_SIZE_SCALE = 0.6;

// Sizes
// 56 48 40 36 32 24

// @vue/component
export default {
  name: 'UserAvatar',

  props: {
    /**
     * User object
     * @type {object}
     */
    user: Object,

    /**
     * Size
     * @type {number}
     * @default 48
     */
    size: {
      type: Number,
      default: 48,
    },

    /**
     * Is image hidden
     * @type {boolean}
     * @default false
     */
    hide_image: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isAccountsPage() {
      return !!this.$route.meta.coachOwnProfile;
    },

    userName() {
      if (this.isAccountsPage) {
        return this.user.full_name;
      } else {
        return this.user?.title ? this.user.title : this.user.full_name;
      }
    },

    isEmpty() {
      return this.hide_image ? true : !this.user.avatar_url;
    },

    avatarStyle() {
      const style = {
        width: this.size + 'px',
        height: this.size + 'px',
      };

      if (this.isEmpty && this.userName) {
        style.fontSize = this.size * FONT_SIZE_SCALE + 'px';
      }

      return style;
    },

    defaultAvatar() {
      if (this.userName) {
        return this.userName.trim().charAt(0).toUpperCase();
      }

      return '';
    },
  },
};
