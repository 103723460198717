import axios from 'axios';
import { NavigationGuardRedirectErrorHandler } from '@/router.js';

import { ROUTER } from '@/router';

export const HTTP = axios.create({
  baseURL: `${import.meta.env.VITE_API_BASE}/${import.meta.env.VITE_API_PATH}`,
});

if (Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone) {
  HTTP.defaults.headers.common['Client-Timezone'] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * @param {Error} error - HttpErrorsHandling
 */
export function HttpErrorsHandling(error) {
  let text;
  let duration = 3000;

  if (axios.isCancel(error)) {
    console.log('Request canceled', error.message);
  } else if (error.response) {
    text = error.response.statusText;

    if (error.response.status == 404) {
      text = 'Not Found';
    } else if (error.response.status == 403) {
      text = 'Forbidden: Access is denied';
    } else if (error.response.status == 406) {
      text = 'Access is denied';
    } else if (typeof error.response.data !== 'string') {
      try {
        text = Object.keys(error.response.data)
          .map((filed) => {
            return Object.keys(error.response.data[filed]).map((e) => {
              return error.response.data[filed][e];
            });
          })
          .toString();
      } catch (e) {
        console.dir(e);
      }
    }

    duration = 5000;
  } else if (error.request) {
    console.dir(error);

    text = error.message || 'Request failed';
  } else {
    text = error.message;
  }

  if (text != undefined) {
    window.FitrApp.$notify({
      group: 'alerts',
      type: 'error',
      title: text,
      duration,
    });
  }
}

HTTP.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        ROUTER.replace({ name: 'onboarding-signin' }).catch(
          NavigationGuardRedirectErrorHandler
        );
      } else if (error.response.status == 404) {
        ROUTER.replace({ name: '404' }).catch(
          NavigationGuardRedirectErrorHandler
        );
      } else if (error.response.status == 403 || error.response.status == 406) {
        ROUTER.replace({ name: 'main' }).catch(
          NavigationGuardRedirectErrorHandler
        );
      }
    }
    return Promise.reject(error);
  }
);
