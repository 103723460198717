<template>
  <b-dropdown class="language-dropdown" variant="white" size="sm" right>
    <template v-if="selectedLanguage.icon" #button-content>
      <span class="d-flex align-items-center">
        <span class="icon mr-2" :class="selectedLanguage.icon" />
        <span class="d-none d-md-inline">{{ selectedLanguage.text }}</span>
      </span>
    </template>

    <template v-for="lang in languageList">
      <b-dropdown-item
        v-if="lang.value != selectedLanguage.value"
        :key="lang.value"
        class="d-flex align-items-center"
        @click="handleSelect(lang)"
      >
        <template v-if="lang.icon">
          <span class="icon icon_flag" :class="lang.icon" />
        </template>
        {{ lang.text }}
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      selected: 'en',
      languageList: [
        {
          value: 'en',
          text: 'English',
          icon: 'icon_flag_us',
        },
        { value: 'es', text: 'Español', icon: 'icon_flag_es' },
        { value: 'fr', text: 'Français', icon: 'icon_flag_fr' },
      ],
    };
  },

  computed: {
    selectedLanguage() {
      return this.languageList.find((l) => l.value === this.selected);
    },

    ...mapGetters(['clientLanguage']),
  },

  watch: {
    clientLanguage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectLanguage(this.findLanguageInList(newVal));
      }
    },
  },

  mounted() {
    this.selectLanguage(this.findLanguageInList(this.clientLanguage));
  },

  methods: {
    /**
     * Select language
     * @param {object} lang - language object
     */
    selectLanguage(lang) {
      this.selected = lang.value;
    },

    /**
     * Handle language selection
     * @param {object} lang - language object
     */
    handleSelect(lang) {
      this.selectLanguage(lang);
      this.$i18n.locale = lang.value;
      this.updateCurrentUserLanguage(lang.value);
    },

    /**
     * Find language in list
     * @param {string} value - language value
     * @returns {object} language object
     */
    findLanguageInList(value) {
      return this.languageList.find((l) => l.value === value);
    },

    ...mapActions(['updateCurrentUserLanguage']),
  },
};
</script>

<style lang="scss" scoped>
.language-dropdown {
  &::v-deep {
    color: $black-100;

    .icon_flag {
      height: 18px;
    }

    .btn {
      display: flex;
      min-width: 0;
      align-items: center;
      padding: 0;
      color: inherit;

      font-size: $font-size-sm;
      font-weight: $font-weight-normal;
      line-height: 1.28571;
      text-transform: none;

      &::after {
        width: 1rem;
        height: 1rem;
        border: none;
        margin-left: 0.5rem;
        background: url('@/assets/images/icons/chevron_sm.svg') center no-repeat;
      }

      &:focus {
        box-shadow: none;
      }
    }

    ul {
      min-width: 10rem;
      margin-top: 0.69rem;
      color: inherit;

      li {
        a {
          display: flex;
          align-items: center;
          padding-top: 0.31rem;
          padding-bottom: 0.31rem;

          font-size: $font-size-sm;
          font-weight: $font-weight-normal;
          gap: 0.5rem;
          line-height: 1.28571;

          &:not(:active) {
            color: inherit;
          }
        }
      }
    }
  }
}
</style>
