import ActionCable from 'actioncable';

/**
 * @type {ActionCable.Cable}
 */
let cable;

/**
 * Connect to ActionCable
 */
function connect() {
  let token = window.localStorage.getItem('access_token');

  cable = ActionCable.createConsumer(
    `${import.meta.env.VITE_WS_BASE}?token=${token}`
  );
}

/**
 * Disconnect from ActionCable
 */
function disconnect() {
  if (cable) {
    cable.disconnect();
  }
}

/**
 * Subscribe
 * @param {string} name - name
 * @param {any} received - received
 * @param {object} params - params
 * @returns {ActionCable.Subscription} - subscription
 */
function subscribe(name, received, params) {
  let channel = {
    channel: name,
  };

  if (params) {
    channel = { ...channel, ...params };
  }

  return cable.subscriptions.create(channel, { received });
}

/**
 * Unsubscribe
 * @param {ActionCable.Channel} channel - channel
 */
function unsubscribe(channel) {
  channel.unsubscribe();
}

export { connect, disconnect, subscribe, unsubscribe };
