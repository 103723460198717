const UserProfileInfo = () => import('./info/index.vue');

export default [
  {
    path: 'profile',
    component: UserProfileInfo,
    name: 'user_profile_info',

    meta: {
      bodyCssClass: 'theme-bg-200',
    },
  },
];
