import { groupBy } from 'lodash-es';

export const notificationsTotal = (state) => state.pagination.total;

export const isReady = (state) => state.pagination.total != undefined;

export const notificationsIDs = (state) =>
  Object.keys(state.notifications).sort((a, b) => {
    return parseInt(b, 10) - parseInt(a, 10);
  });

export const sortedNotifications = (state, getters) => {
  return getters.notificationsIDs.map((id) => state.notifications[id]);
};

// Get notifications by Type
export const notificationsByType = (state, getters) => {
  if (getters.sortedNotifications.length > 0 && !!state.activityType) {
    return getters.sortedNotifications.filter(
      (n) => n.activity_type == state.activityType
    );
  } else {
    return getters.sortedNotifications;
  }
};

export const notificationsSize = (state, getters) =>
  getters.notificationsByType.length;

// Group by day
export const groupedNotifications = (state, getters) => {
  return groupBy(getters.notificationsByType, 'date_key');
};

export const latestID = (state, getters) => {
  let last = getters.notificationsByType[getters.notificationsSize - 1];

  if (last != undefined) {
    return last.id;
  } else {
    return null;
  }
};

export const isPaginable = (state, getters) => {
  return getters.latestID > state.pagination.min_offset;
};

// Unread notifications
export const unreadNotifications = (state, getters) => {
  let lastIDs = getters.notificationsIDs.slice(0, 5);

  return lastIDs.map((id) => state.notifications[id]);
};
